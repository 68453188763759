import React from "react";
import { useInView } from "react-intersection-observer";

import Carousel from "./Carousel";
import { allProductDetails } from "../../../Constants/AllProductDetails";

const Products = () => {
  const { ref: titleRef, inView: titleInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const { ref: carouselRef, inView: carouselInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <section
      id="workflows"
      className="flex min-h-[100vh] pt-[4rem] py-8 md:px-5 2xl:px-0 overflow-hidden"
    >
      <div className="flex flex-col justify-center space-y-14 h-full w-full max-w-7xl mx-auto my-auto">
        <div
          ref={titleRef}
          className={`flex flex-col justify-between space-y-4 items-center text-center px-5 md:px-0 ${
            titleInView ? "visible animate-fade-in-top" : "invisible"
          }`}
        >
          <h1 className="text-3xl">
            Unique products to accelerate subsurface workflows
          </h1>
          <h3 className="text-xl md:max-w-[60%]">
            Our product workflows are completely integrated with existing
            software and continuously updated based on our learnings from global
            deployments.
          </h3>
        </div>
        <div
          ref={carouselRef}
          className={`flex justify-center ${
            carouselInView ? "visible animate-fade-in-bottom" : "invisible"
          }`}
        >
          <Carousel products={allProductDetails} />
        </div>
      </div>
    </section>
  );
};

export default Products;
