import React from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

const Intro = () => {
  const { ref: titleRef, inView: titleInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const { ref: imageRef, inView: imageInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <section
      id="intro"
      className="flex min-h-[100vh] pt-[4rem] py-8 px-5 2xl:px-0 overflow-hidden"
    >
      <div
        className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 justify-around lg:justify-between 
        max-w-7xl w-full mx-auto my-auto items-center h-full"
      >
        <div
          ref={titleRef}
          className={`flex flex-col justify-between lg:max-h-[20rem] max-w-[32rem] lg:w-[32rem] my-auto space-y-4 ${
            titleInView ? "visible animate-fade-in-left" : "invisible"
          } `}
        >
          <h1 className="text-4xl lg:text-5xl font-semibold">
            Reservoir management and surveillance redefined.
          </h1>
          <h3 className="text-xl">
            Automate surveillance & accelerate management.
          </h3>
          <div className="flex justify-end">
            <Link
              to="/products"
              className="text-xl text-dark_primary hover:text-dark_primary transition 
              duration-200 bg-medium_secondary px-5 py-1 rounded-2xl"
            >
              Get Started
            </Link>
          </div>
        </div>
        <div
          ref={imageRef}
          className={`flex w-full max-w-[32rem] max-h-[30rem] my-auto border-[20px] rounded-[40px] border-light_secondary ${
            imageInView ? "visible animate-fade-in-right" : "invisible"
          }`}
        >
          <img
            src="https://images.unsplash.com/photo-1597733336794-12d05021d510?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2574&q=80"
            alt="intro"
            className="rounded-[20px] opacity-85 aspect-auto"
          />
        </div>
      </div>
    </section>
  );
};

export default Intro;
