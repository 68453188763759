import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

import { useParams } from "react-router-dom";
import { allProjectDetails } from "../Constants/AllProjectDetails";

const ProjectDetails = () => {
  // Project rendering configuration -->
  const params = useParams();
  const [selectedProject, setSelectedProject] = useState({});

  useEffect(() => {
    const projectId = params.projectId;
    const foundProject = allProjectDetails.find(
      (project) => project.id.toString() === projectId
    );
    setSelectedProject(foundProject);
  }, [params.projectId]);
  // <-- Project rendering configuration

  // Animation configuration -->
  const { ref: headerRef, inView: headerInView } = useInView({
    triggerOnce: true,
  });

  const { ref: textRef, inView: textInView } = useInView({
    triggerOnce: true,
  });
  // <-- Animation configuration

  return (
    <section className="flex min-h-[100vh] pt-[4rem] py-8 2xl:px-0">
      <div className="flex flex-col justify-center space-y-5 items-center w-full h-full py-8">
        <div
          ref={headerRef}
          className={`flex max-h-[20rem] w-full mx-auto my-auto relative z-0 ${
            headerInView ? "visible animate-fade-in" : "invisible"
          }`}
        >
          <img
            alt="background"
            src={selectedProject.image}
            className="w-full h-full object-cover max-h-[20rem] border-purple-400"
          />
          <div className="flex flex-col justify-center space-y-6 absolute w-full h-full z-1 bg-black bg-opacity-50">
            <h1 className="text-xl sm:text-3xl text-center text-slate-50 ">
              {selectedProject.title}
            </h1>
            <div className="flex justify-around font-normal w-full max-w-3xl mx-auto text-center">
              {/* <h1 className="text-slate-50 text-sm sm:text-lg w-[30%] ">
                {selectedProject.company}
              </h1> */}
              {/* <h1 className="text-slate-50 text-sm sm:text-lg w-[30%] ">
                {selectedProject.date}
              </h1> */}
              <h1 className="text-slate-50 text-sm sm:text-lg w-[30%] ">
                {selectedProject.category}
              </h1>
            </div>
          </div>
        </div>
        <div
          ref={textRef}
          className={`flex flex-col justify-between max-w-4xl px-5 lg:px-0 ${
            textInView ? "visible animate-fade-in-bottom" : "invisible"
          }`}
        >
          <p className="text-lg">{selectedProject.description}</p>
        </div>
      </div>
    </section>
  );
};

export default ProjectDetails;
