import React from "react";
// import { useNavigate } from "react-router-dom";

const ResourceCard = ({ resource }) => {
  // const navigate = useNavigate();

  // const handleClick = () => {
  //   navigate(`/resources/${resource.id}`);
  // };

  return (
    <a
      className="flex flex-col justify-between w-[16rem] h-[18rem] max-w-[16rem] max-h-[18rem] cursor-pointer
      bg-slate-50 rounded-xl border-slate-300 border-[1px] shadow-md hover:scale-105 transition duration-200"
      href={resource.link}
      target="_blank"
      rel="noreferrer"
      // onClick={handleClick}
    >
      <div className="flex w-full h-full max-h-[65%] rounded-t-lg">
        <img
          src={resource.image}
          alt="project"
          className="w-full h-full object-cover rounded-t-lg"
        />
      </div>
      <div className="flex flex-col justify-center px-2 h-[35%]">
        <div className="flex justify-between py-1 h-[30%] items-center">
          <h3 className="text-md">{resource.subject}</h3>
          <h3 className="text-md">{resource.date}</h3>
        </div>
        <h1 className="text-xl font-semibold pb-2 h-[55%] line-clamp-2">
          {resource.title}
        </h1>
      </div>
    </a>
  );
};

export default ResourceCard;
