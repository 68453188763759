import React from "react";
import { useInView } from "react-intersection-observer";

import Form from "./Form";

const Contact = () => {
  const { ref: sectionRef, inView: sectionInView } = useInView({
    triggerOnce: true,
  });

  return (
    <section
      ref={sectionRef}
      id="contact"
      className={`flex min-h-[100vh] pt-[4rem] py-8 md:px-5 2xl:px-0 ${
        sectionInView ? "visible animate-fade-in-bottom" : "invisible"
      }`}
    >
      <div className="flex flex-col justify-center space-y-14 items-center h-full w-full max-w-7xl mx-auto my-auto">
        <h1 className="text-3xl">Contact Us</h1>
        <Form />
      </div>
    </section>
  );
};

export default Contact;
