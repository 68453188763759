import React, { useState } from "react";
import { SendIcon } from "../../UI/Icons";

const Form = () => {
  const defaultFormValues = {
    name: "",
    email: "",
    phone: "",
    company: "",
    body: "",
  };

  const [formValues, setFormValues] = useState(defaultFormValues);

  const { name, email, phone, company, body } = formValues;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (formValues) => {
    /* 
      //TODO: Add form submission logic
    */
  };

  return (
    <form
      className="flex flex-col space-y-4 sm:space-y-6 justify-between max-w-4xl w-full px-4 md:px-0"
      action="POST"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 justify-between">
        <input
          className="w-full sm:w-[45%] bg-slate-100 px-2 py-2 rounded-lg shadow-md outline-none focus:bg-slate-50 transition duration-100"
          placeholder="Your Name"
          name="name"
          value={name}
          onChange={handleChange}
        />
        <input
          className="w-full sm:w-[45%] bg-slate-100 px-2 py-2 rounded-lg shadow-md outline-none focus:bg-slate-50 transition duration-100"
          placeholder="Your Email"
          name="email"
          value={email}
          onChange={handleChange}
        />
      </div>
      <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 justify-between">
        <input
          className="w-full sm:w-[45%] bg-slate-100 px-2 py-2 rounded-lg shadow-md outline-none focus:bg-slate-50 transition duration-100"
          placeholder="Phone Number"
          name="phone"
          value={phone}
          onChange={handleChange}
        />
        <input
          className="w-full sm:w-[45%] bg-slate-100 px-2 py-2 rounded-lg shadow-md outline-none focus:bg-slate-50 transition duration-100"
          placeholder="Company Name"
          name="company"
          value={company}
          onChange={handleChange}
        />
      </div>
      <textarea
        placeholder="Your Message"
        name="body"
        value={body}
        onChange={handleChange}
        className="bg-slate-100 h-[12rem] w-full mx-auto my-auto shadow-md px-2 py-2 rounded-lg outline-none resize-none focus:bg-slate-50 transition duration-100"
      ></textarea>
      <button className="flex marker:max-w-[12rem] mx-auto items-center">
        Submit
        <SendIcon />
      </button>
    </form>
  );
};

export default Form;
