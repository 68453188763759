import React from "react";
import { useNavigate } from "react-router-dom";

const ProjectCard = ({ project }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/projects/${project.id}`);
  };

  return (
    <div
      className="flex flex-col justify-between w-[16rem] h-[18rem] max-w-[16rem] max-h-[18rem] cursor-pointer
    bg-slate-50 rounded-xl border-slate-300 border-[1px] shadow-md hover:scale-105 transition duration-200"
      onClick={handleClick}
    >
      <div className="flex w-full h-full max-h-[75%] rounded-t-lg">
        <img
          src={project.image}
          alt="project"
          className="w-full h-full object-cover rounded-t-lg"
        />
      </div>
      <div className="flex flex-col justify-center px-2 h-[35%]">
        <h1 className="text-xl font-semibold pb-2 h-[80%] line-clamp-2">{project.title}</h1>
      </div>
    </div>
  );
};

export default ProjectCard;
