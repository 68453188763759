import React, { useState } from "react";
import { HamburgerIcon, CloseIcon } from "../Icons";
import MobileMenu from "./MobileMenu";
import DesktopMenu from "./DesktopMenu";
import Logo from "./Logo";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="items-center w-[100%] bg-light_primary py-3 fixed z-20">
      <div className="flex justify-between items-center mx-auto max-w-7xl px-5 2xl:px-0">
        <Logo />
        <span onClick={toggleMenu} className="lg:hidden">
          {isMenuOpen ? <CloseIcon /> : <HamburgerIcon />}
        </span>
        <DesktopMenu />
      </div>
      <MobileMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
    </nav>
  );
};

export default Navbar;
