import React, { useEffect, useState, useRef } from "react";
import ProductCard from "./ProductCard";

import { LeftScrollArrow, RightScrollArrow } from "../../UI/Icons";

const Carousel = ({ products }) => {
  const [arrowsVisible, setArrowsVisible] = useState(false);

  const carousel = useRef();

  const leftScrollHandler = () => {
    carousel.current.scrollLeft -= carousel.current.offsetWidth;
  };

  const rightScrollHandler = () => {
    carousel.current.scrollLeft += carousel.current.offsetWidth;
  };

  useEffect(() => {
    if (products.length > 4) {
      setArrowsVisible(true);
    }
  }, [products.length]);

  return (
    <div
      name="carousel-wrapper"
      className="flex justify-between relative min-w-screen"
    >
      <div
        className="h-full w-[1rem] md:min-w-[3rem] 
          absolute z-10 cursor-pointer left-0 flex items-center 
          bg-gradient-to-r from-light_primary"
      >
        <div
          className={`${
            arrowsVisible ? "md:opacity-100" : "opacity-0 hover:opacity-100"
          } transition duration-200 active:opacity-30 opacity-0 hidden md:flex`}
          onClick={leftScrollHandler}
        >
          <LeftScrollArrow />
        </div>
      </div>
      <div
        name="carousel"
        ref={carousel}
        className="flex hide-scrollbar snap-x snap-mandatory snap-always scroll-smooth
           space-x-4 px-10 py-4 min-w-[18rem] max-w-7xl w-screen overflow-x-auto my-auto mx-auto
           md:mx-auto place-items-center relative"
      >
        {products.map((product) => (
          <ProductCard product={product} key={product.id} />
        ))}
      </div>
      <div
        className="h-full w-[1rem] md:min-w-[3rem]
          absolute z-10 cursor-pointer right-0 flex items-center
          bg-gradient-to-l from-light_primary"
      >
        <div
          className={`${
            arrowsVisible ? "md:opacity-100" : "opacity-0 hover:opacity-100"
          } transition duration-200 active:opacity-30 opacity-0 hidden md:flex`}
          onClick={rightScrollHandler}
        >
          <RightScrollArrow />
        </div>
      </div>
    </div>
  );
};

export default Carousel;
