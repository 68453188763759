import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

import { useParams } from "react-router-dom";
import { allResourceDetails } from "../Constants/AllResourceDetails";

const ResourceDetails = () => {
  // Resource rendering configuration -->
  const params = useParams();
  const [selectedResource, setSelectedResource] = useState({});

  useEffect(() => {
    const resourceId = params.resourceId;
    const foundResource = allResourceDetails.find(
      (resource) => resource.id.toString() === resourceId
    );
    setSelectedResource(foundResource);
  }, [params.resourceId]);
  // <-- Resource rendering configuration

  // Animation configuration -->
  const { ref: headerRef, inView: headerInView } = useInView({
    triggerOnce: true,
  });

  const { ref: textRef, inView: textInView } = useInView({
    triggerOnce: true,
  });
  // <-- Animation configuration

  return (
    <section className="flex min-h-[100vh] pt-[4rem] py-8 2xl:px-0">
      <div className="flex flex-col justify-center space-y-5 items-center w-full h-full py-8">
        <div
          ref={headerRef}
          className={`flex max-h-[20rem] w-full mx-auto my-auto relative z-0 ${
            headerInView ? "visible animate-fade-in" : "invisible"
          }`}
        >
          <img
            alt="background"
            src={selectedResource.image}
            className="w-full h-full object-cover max-h-[20rem] border-purple-400"
          />
          <div className="flex flex-col justify-center space-y-6 absolute w-full h-full z-1 bg-black bg-opacity-50 px-3">
            <h1 className="text-xl sm:text-3xl text-center text-slate-50 ">
              {selectedResource.title}
            </h1>
            <h3 className="text-slate-50 text-lg text-center font-semibold max-w-2xl mx-auto">
              {selectedResource.heading}
            </h3>
          </div>
        </div>
        <div
          ref={textRef}
          className={`flex flex-col space-y-3 justify-between max-w-4xl px-5 lg:px-0 ${
            textInView ? "visible animate-fade-in-bottom" : "invisible"
          }`}
        >
          <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:justify-start sm:space-x-4 items-center">
            <img
              src={selectedResource.authorImage}
              alt="author"
              className="w-16 h-16 object-cover rounded-full border-2 border-slate-300 shadow-sm"
            />
            <h3 className="bg-slate-200 px-2 py-1 rounded-lg text-slate-800 shadow-sm">{`By ${selectedResource.authorName}`}</h3>
            <h3 className="bg-slate-200 px-2 py-1 rounded-lg text-slate-800 shadow-sm">
              {selectedResource.date}
            </h3>
          </div>
          <p className="text-lg">{selectedResource.description}</p>
        </div>
      </div>
    </section>
  );
};

export default ResourceDetails;
