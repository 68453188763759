import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="flex flex-col space-y-4 items-center justify-center min-h-[16rem] py-4 left-0 bottom-0">
      <div className="w-full h-full flex flex-col space-y-8 md:flex-row md:space-y-0 md:px-5 2xl:px-0 justify-between max-w-7xl">
        <div className="flex flex-col justify-center space-y-1 text-center md:text-left md:w-[30%]">
          <h1 className="text-2xl text-dark_primary">Contact</h1>
          <p className="font-thin">contact@resermine.com</p>
          <p className="font-thin">
            251 Little Falls Dr Wilmington DE 19808, USA
          </p>
        </div>
        <div className="flex flex-col justify-between space-y-1 text-center md:w-[30%]">
          <h1 className="text-2xl text-dark_primary">Site</h1>
          <Link
            className="font-thin hover:opacity-60 transition duration-200"
            to="/products"
          >
            Products
          </Link>
          <Link
            className="font-thin hover:opacity-60 transition duration-200"
            to="/projects"
          >
            Projects
          </Link>
          <Link
            className="font-thin hover:opacity-60 transition duration-200"
            to="/resources"
          >
            Resources
          </Link>
        </div>
        <div className="flex flex-col space-y-1 text-center md:text-right md:w-[30%]">
          <h1 className="text-2xl text-dark_primary">Social</h1>
          <a
            className="font-thin hover:opacity-60 transition duration-200"
            href="https://www.linkedin.com/company/resermine/"
            target="_blank"
            rel="noreferrer"
          >
            LinkedIn
          </a>
        </div>
      </div>
      <h3 className="text-lg font-thin">Resermine Inc. &copy; {year} </h3>
    </footer>
  );
};

export default Footer;
