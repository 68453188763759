export const mapLocations = [
  {
    id: 1,
    latitude: 23.633529,
    longitude: 58.217854,
    name: "Muscat, Oman",
  },
  {
    id: 2,
    latitude: 25.15565,
    longitude: 55.300311,
    name: "Dubai, UAE",
  },
  {
    id: 3,
    latitude: 3.16202052,
    longitude: 101.716907,
    name: "Kuala Lumpur, Malaysia",
  },
  {
    id: 4,
    latitude: 25.276987,
    longitude: 51.520008,
    name: "Doha, Qatar",
  },
  {
    id: 5,
    latitude: 39.761338,
    longitude: -75.622855,
    name: "United States",
  },
];
