import React from "react";
import { NavLink } from "react-router-dom";

const DesktopMenu = () => {
  const style =
    "text-xl text-dark_primary hover:-translate-y-[3px] transition duration-200 px-3 py-1 rounded-2xl";

  const activeStyle = {
    textDecoration: "underline",
    textUnderlineOffset: "7px",
  };

  return (
    <div className="hidden lg:flex space-x-16 items-center">
      <NavLink
        style={({ isActive }) => {
          return isActive ? activeStyle : undefined;
        }}
        to="/products"
        className={style}
        end
      >
        Products
      </NavLink>
      <NavLink
        style={({ isActive }) => {
          return isActive ? activeStyle : undefined;
        }}
        to="/projects"
        className={style}
        end
      >
        Projects
      </NavLink>
      <NavLink
        style={({ isActive }) => {
          return isActive ? activeStyle : undefined;
        }}
        to="/resources"
        className={style}
        end
      >
        Resources
      </NavLink>
      <NavLink
        style={({ isActive }) => {
          return isActive ? activeStyle : undefined;
        }}
        to="/about"
        className={style}
        end
      >
        About
      </NavLink>
      <NavLink
        to="/products"
        className="text-xl text-dark_primary hover:text-dark_primary transition 
          duration-200 bg-medium_secondary px-5 py-1 rounded-2xl"
      >
        Get Started
      </NavLink>
    </div>
  );
};

export default DesktopMenu;
