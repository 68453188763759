import React, { Fragment } from "react";
import Introduction from "./Introduction";
// import Members from "./Team/Members";

const AboutUs = () => {
  return (
    <Fragment>
      <Introduction />
      {/* <Members/> */}
    </Fragment>
  );
};

export default AboutUs;
