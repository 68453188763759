import React from "react";
import { useInView } from "react-intersection-observer";

import ResourceCard from "./ResourceCard";
import { allResourceDetails } from "../../../Constants/AllResourceDetails";

const Resources = () => {
  const { ref: sectionRef, inView: sectionInView } = useInView({
    triggerOnce: true,
  });

  return (
    <section
      ref={sectionRef}
      id="resources"
      className={`flex min-h-[100vh] pt-[4rem] py-8 md:px-5 2xl:px-0 ${
        sectionInView ? "visible animate-fade-in-bottom" : "invisible"
      }`}
    >
      <div className="flex flex-col space-y-8 md:space-y-16 my-auto mx-auto w-full">
        <h1 className="text-3xl text-center">Resources</h1>
        <div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 w-full max-w-7xl 
              mx-auto my-auto place-items-center"
        >
          {allResourceDetails.map((resource) => (
            <ResourceCard key={resource.id} resource={resource} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Resources;
