import React from "react";
import { Link } from "react-router-dom";

const ProductCard = ({ product }) => {
  return (
    <div
      className="flex flex-col justify-between space-y-4 max-w-[18rem] snap-center hover:scale-105 transition duration-200"
      key={product.id}
    >
      <div
        className="flex flex-col justify-between w-[18rem] h-[22rem] shadow-lg rounded-lg py-4 px-3 
    border-slate-100 border-2"
      >
        {/* <div className="flex h-[25%]">
          <img
            alt="icon"
            src={product.image}
            className="object-cover aspect-square rounded-full"
          />
        </div> */}
        <div className="mt-[10%] flex flex-col justify-around h-[70%]">
          <h3 className="text-xl font-semibold">{product.name}</h3>
          <p className="line-clamp-[7]">{`${product.preview}`}</p>
        </div>
      </div>
      <Link
        to={`/products/${product.id}`}
        className="text-xl text-dark_primary hover:text-dark_primary transition 
        duration-200 bg-medium_secondary px-5 py-3 rounded-2xl text-center"
      >
        Learn More
      </Link>
    </div>
  );
};

export default ProductCard;
