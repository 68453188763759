import Map, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import { mapLocations } from "../../../Constants/MapLocationData";
import { LocationMarkerCircleIcon } from "../../UI/Icons";

const OfficesMap = () => {
  const initialViewport = {
    latitude: 15.554237,
    longitude: 15.5542,
    zoom: 1.0,
  };

  return (
    <div className="flex min-h-full w-full overflow-hidden rounded-xl">
      <Map
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        initialViewState={initialViewport}
        projection="mercator"
        mapStyle="mapbox://styles/mapbox/light-v11"
        style={{ height: "20rem", minWidth: "100%" }}
      >
        {mapLocations.map((location) => (
          <Marker
            key={location.id}
            latitude={location.latitude}
            longitude={location.longitude}
          >
            <LocationMarkerCircleIcon />
          </Marker>
        ))}
      </Map>
    </div>
  );
};

export default OfficesMap;
