import React from "react";
import { Link } from "react-router-dom";

const MobileMenu = ({ isMenuOpen, setIsMenuOpen }) => {
  const style =
    "block hover:bg-medium_primary hover:text-light_primary border-b-[1px] border-opacity-20 border-slate-300 px-4 py-4 text-xl text-center font-normal transition duration-200";

  return (
    <div name="mobile-menu" className="flex lg:hidden z-10">
      <div
        className={`${
          !isMenuOpen ? "hidden" : "flex"
        } flex-col w-full absolute bg-light_primary shadow-xl`}
      >
        <Link
          to="/products"
          onClick={() => {
            setIsMenuOpen(false);
          }}
          className={style}
        >
          Products
        </Link>
        <Link
          to="/projects"
          onClick={() => {
            setIsMenuOpen(false);
          }}
          className={style}
        >
          Projects
        </Link>
        <Link
          to="/resources"
          onClick={() => {
            setIsMenuOpen(false);
          }}
          className={style}
        >
          Resources
        </Link>
        <Link
          to="/about"
          onClick={() => {
            setIsMenuOpen(false);
          }}
          className={style}
        >
          About
        </Link>
        <Link
          to="/products"
          onClick={() => {
            setIsMenuOpen(false);
          }}
          className={style}
        >
          <p className="bg-medium_primary text-dark_primary max-w-[10rem] px-1 py-1 mx-auto rounded-xl">
            Get Started
          </p>
        </Link>
      </div>
    </div>
  );
};

export default MobileMenu;
