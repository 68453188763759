export const allProjectDetails = [
  {
    id: 1,
    image:
      "https://images.unsplash.com/photo-1651993358890-a2b9a6c4d4c6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=5187&q=80",
    title: "E&P Operator in Middle East",
    company: "",
    date: "",
    category: "Surveillance",
    description: `Operator with a field that had over 100 injector wells and having commingled production (no history-matched model) from four layers and with a 30% decline in production was seeking solutions to optimize injection. Our hybrid workflows helped determine fluid as well as oil contribution resulting in identifying which layers are watering out and which layers have more oil to target. HawkEye’s optimization workflows further helped determine a new injection strategy for water redistribution to increase oil by 5% - which wells to increase and which ones to decrease to arrest declining production. Recommendations are being implemented
    `,
  },
  {
    id: 2,
    image:
      "https://images.unsplash.com/photo-1563118351-26b2c7381731?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=5071&q=80",
    title: "E&P Operator in the Middle East",
    company: "",
    date: "",
    category: "Surveillance",
    description: `The operator with the waterflood field was seeking new workflows to determine which patterns to implement polymer injection as traditional approaches (reservoir simulation history matching and polymer prediction were taking over 11 months). Our hybrid model workflows established connectivity in a month (10X acceleration) and were benchmarked with tracer tests. The combination of connectivity analysis and BL analytical models for residual oil saturations helped classify patterns into type 1 (recommended for polymer injection), type 2 (recommended for water injection optimization), and type 3 (not recommended for polymer injection). The operator is scaling the successful hybrid workflows from software to other fields.`,
  },
  {
    id: 3,
    image:
      "https://images.unsplash.com/photo-1624905181181-408618e9f4ab?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=5199&q=80",
    title: "E&P Operator in the Middle East",
    company: "",
    date: "",
    category: "Surveillance",
    description: `The operator with a recent waterflood field was seeking a solution to determine the reason for high water production being observed from producers (over 90% water cut). Being a recent waterflood, traditional CRM model workflows are not applicable. ML model workflows in our surveillance product were used to compare different data sets and delineate which specific injectors were causing recycling. Connectivity analysis from hybrid model workflows was benchmarked with tracer tests resulting in avoiding expensive and time-consuming tracer tests. The proposed recommendations from the software are being implemented.`,
  },
  {
    id: 4,
    image:
      "https://images.unsplash.com/photo-1598114335556-119dbed6b051?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=5070&q=80",
    title: "E&P Operator in California, USA",
    company: "",
    date: "",
    category: "Surveillance",
    description: `Operator with over 800 wells and 24 different layers was seeking opportunities to optimize waterflood. Our product workflows helped determine the connectivity of injector and producer wells in every layer as well as fluid and oil production coming from individual layers. This helped determine which wells to shut (to avoid recycling of water) and which wells to increase water injection (given their potential for remaining oil). HawkEye optimization workflows were set with individual injector well constraints to further obtain different injection strategies for low oil prices (reduce operational costs and hence water production) as well as for high oil prices (increase oil production),`,
  },
  {
    id: 5,
    image:
      "https://images.unsplash.com/photo-1648369000096-109763c11e8e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=5060&q=80",
    title: "E&P Operator in Houston, USA",
    company: "",
    date: "",
    category: "Surveillance",
    description: `Operator with over 150 wells and implementing CO2 WAG in the field was seeking to increase utilization of CO2 from injectors. Hybrid model workflows helped connectivity as well as delineate specific injectors impacting oil recovery from each producer. HawkEye optimization workflows helped obtain a new injection strategy for higher CO2 utilization. Multiple model solutions were compared for consistency and recommendations are currently being implemented.  
    `,
  },
  {
    id: 6,
    image:
      "https://images.unsplash.com/photo-1578356058390-f58c575337a2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=5070&q=80",
    title: "E&P Operator in Malaysia",
    company: "",
    date: "",
    category: "Surveillance",
    description: `Operator with only a static model and no dynamic model was seeking opportunities to identify well locations for an infill campaign in the field. Hybrid model workflows helped quantify sweep and HawkEye optimization workflows and recommended new water redistribution strategies as zero CAPEX solutions to increase oil. Hybrid model workflows identified regions that were not swept and helped determine where to place the next injector. FMM workflows were used to rank all the identified infill wells to help the operator prioritize the drilling campaign. Initial drilling results have been positive.`,
  },
  {
    id: 7,
    image:
      "https://images.unsplash.com/photo-1572970388430-a7fff761e597?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=5070&q=80",
    title: "E&P Operator in Europe",
    company: "",
    date: "",
    category: "Surveillance",
    description: `Operator with compartmentalized field with long horizontal was seeking solutions to optimize waterflood. A dynamic model was available, but there were uncertainties in fault transmissibilities. Hybrid model workflows were used to determine a new injection strategy to increase oil production by 5 – 10% through water redistribution without any CAPEX. The faults’ transmissibility was refined leading to improved history match quality of the model. The results from hybrid model workflows were benchmarked with reservoir simulation results to showcase how this practical approach can accelerate water injection optimization.`,
  },
  {
    id: 8,
    image:
      "https://images.unsplash.com/photo-1642357367212-3828d3916e54?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=4306&q=80",
    title: "E&P Operator in North Africa",
    company: "",
    date: "",
    category: "Surveillance",
    description: `Operator with a highly compartmentalized and multilayer field was having trouble with history matching the reservoir model. Hybrid model workflows helped determine connectivity that was benchmarked with salinity data (the contrast between in situ and injected water). This analysis helped determine that one layer wasn’t receiving any water. The operator was able to drill a new injection well and observed an increase in oil recovery. This deployment of our modeling workflows was covered by JPT as an upcoming technology.`,
  },
  {
    id: 9,
    image:
      "https://images.unsplash.com/photo-1511700482891-ee930f7bfd88?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=4000&q=80",
    title: "E&P Operator in South America",
    company: "",
    date: "",
    category: "Surveillance",
    description: `Operator with a highly compartmentalized field with over 200 wells was seeking solutions to identify effective injectors. Hybrid model workflows were used to determine connectivities that helped establish how producers did not see any injection but from one injector. This analysis helped the operator revise the concept of field development from water to gas injection and identify which injectors to inject gas.`,
  },
  {
    id: 10,
    image:
      "https://images.unsplash.com/photo-1588011930968-eadac80e6a5a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=5069&q=80",
    title: "E&P Operator in the Middle East",
    company: "",
    date: "",
    category: "CO2 Management",
    description: `The operator is seeking to implement a pilot CO2 huff and puff injection in a large unconventional field. As preparation for the pilot injection, the operator was seeking optimal values of several variables in the pilot injection. The computational time for traditional simulation workflows was prohibitively expensive, and advanced optimization capabilities were sought. Our hybrid model workflows combined neural networks with reservoir simulations decreasing the total number of simulations required to find the optimum. Besides, HawkEye’s advanced optimization capabilities were used to determine the optimum value of variables for the injection operation. The new capabilities and insights from software workflow results are being further investigated as plans are underway for CO2 huff and puff injection. `,
  },
  {
    id: 11,
    image:
      "https://images.unsplash.com/photo-1624905181196-4ee8cb2522c8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=5168&q=80",
    title: "E&P Operator in the Middle East",
    company: "",
    date: "",
    category: "CO2 Management",
    description: `The operator is seeking to optimize the field development plan for a large unconventional field using advanced computational tools. Our software workflows are used to map location to recovery and advanced optimization is helping determine pad number and their orientation for a given section of the field. These results are further being used to analyze optimal development plan – including well orientation optimization in individual pads as well as determine the sequencing of pads to be brought online to meet the gas demand.`,
  },
  {
    id: 12,
    image:
      "https://images.unsplash.com/photo-1648555394313-494797ad48fc?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=5140&q=80",
    title: "E&P Operator in Malaysia",
    company: "",
    date: "",
    category: "HawkEye FDP",
    description: `Operator with a larger reservoir (2 million active grids) and with 27 different layers was seeking new solutions to determine optimum well trajectories in the reservoir. The traditional manual process of placing wells and running simulation runs was time-consuming and the team had taken 11 months to determine two well locations. Our hybrid model workflows helped determine well locations in a month (10X acceleration). Our advanced optimization workflows helped further optimize well trajectories (with 30% higher production than originally planned), identify alternate trajectories with similar production potential (in case of drilling surprises), identify potential sidetracks (to increase production by 10 – 20%) as well as identify well locations and quantify production from two new regions (out of platform opportunities) in the fields (not being originally considered).`,
  },
  {
    id: 13,
    image:
      "https://images.unsplash.com/photo-1565433173417-e9f2b7eee628?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3784&q=80",
    title: "E&P Operator in Japan",
    company: "",
    date: "",
    category: "HawkEye FDP",
    description: `Operator with a large reservoir and with very high reservoir simulation times was seeking solutions to accelerate field development. Our hybrid model workflows helped determine new well locations and find an optimum number to maximize NPV. These were further benchmarked with traditional processes to quantify the acceleration (10X).`,
  },
];
