import React from "react";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link
      to="/"
      className="flex space-x-2 items-center hover:text-dark_primary hover:scale-105 transition duration-200"
    >
      <img src="/assets/logo.png" alt="logo" className="w-[3rem] h-[2.5rem]" />
      <h1 className="text-2xl font-semibold">Resermine</h1>
    </Link>
  );
};

export default Logo;
