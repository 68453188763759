import { Fragment } from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "./Components/UI/Navbar/Navbar";
import Home from "./Components/Home";
import ProductDetails from "./Routes/ProductDetails";
import ProjectDetails from "./Routes/ProjectDetails";
import ResourceDetails from "./Routes/ResourceDetails";
import Footer from "./Components/UI/Footer";
import ScrollToTop from "./Components/UI/ScrollToTop";
import Products from "./Components/Sections/Products/Products";
import Projects from "./Components/Sections/Projects/Projects";
import Resources from "./Components/Sections/Resources/Resources";
import AboutUs from "./Components/Sections/AboutUs/AboutUs";
import Contact from "./Components/Sections/Contact/Contact";
import NotFound from "./Components/Sections/NotFound";

function App() {
  return (
    <Fragment>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/:productName" element={<ProductDetails />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/:projectId" element={<ProjectDetails />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/resources/:resourceId" element={<ResourceDetails />} />
        <Route path="/about" element={<AboutUs />}/>
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Fragment>
  );
}

export default App;
