import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

import { useParams } from "react-router-dom";
import { allProductDetails } from "../Constants/AllProductDetails";

const ProductDetails = () => {
  // Product rendering configuration -->
  const params = useParams();
  const [selectedProduct, setSelectedProduct] = useState({});

  useEffect(() => {
    const productName = params.productName;
    const foundProduct = allProductDetails.find(
      (product) => product.id.toString() === productName
    );
    setSelectedProduct(foundProduct);
  }, [params.productName]);
  // <-- Product rendering configuration

  // Animation configuration -->
  const { ref: imageRef, inView: imageInView } = useInView({
    triggerOnce: true,
  });

  const { ref: textRef, inView: textInView } = useInView({
    triggerOnce: true,
  });
  // <-- Animation configuration

  return (
    <main className="flex min-h-[100vh] pt-[4rem] py-8 px-5 2xl:px-0 overflow-hidden">
      <div className="flex flex-col lg:flex-row items-center justify-between lg:space-x-10 space-y-4 lg:space-y-0 w-full max-w-7xl mx-auto my-auto">
        <div
          ref={imageRef}
          className={`flex w-full h-full max-w-[26rem] max-h-[24rem] lg:max-w-[32rem] lg:max-h-[30rem] my-auto rounded-[40px] ${
            imageInView ? "visible animate-fade-in-left" : "invisible"
          }`}
        >
          <img
            src={selectedProduct.image}
            alt={selectedProduct.name}
            className="rounded-[20px] opacity-85 aspect-square object-cover"
          />
        </div>
        <div
          ref={textRef}
          className={`flex flex-col justify-center space-y-4 text-left ${
            textInView ? "visible animate-fade-in-right" : "invisible"
          }`}
        >
          <h1 className="text-center text-3xl">{selectedProduct.name}</h1>
          <p
            dangerouslySetInnerHTML={{ __html: selectedProduct.description }}
            className="text-xl max-w-[40rem]"
          ></p>
        </div>
      </div>
    </main>
  );
};

export default ProductDetails;
