import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <section className="min-h-[80vh] flex justify-center items-center">
      <div className="w-full max-w-7xl flex flex-col space-y-4 text-center items-center">
        <h1 className="text-5xl text-dark_primary">404</h1>
        <h2 className="text-3xl text-dark_secondary">Page Not Found</h2>
        <Link
          className="text-2xl text-light_primary bg-medium_primary max-w-[16rem] rounded-lg py-1 px-5 hover:bg-medium_secondary transition duration-200"
          to="/"
        >
          Back to Home
        </Link>
      </div>
    </section>
  );
};

export default NotFound;
