export const allResourceDetails = [
  {
    id: 1,
    image:
      "https://images.unsplash.com/photo-1676031957170-ce1e17f8e979?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    title:
      "A Hybrid Neural Workflow for Optimal Water-Alternating-Gas Flooding",
    heading:
      "A Hybrid Neural Workflow for Optimal Water-Alternating-Gas Flooding",
    link: "https://onepetro.org/SPERCSC/proceedings/22RCSC/2-22RCSC/D021S013R002/515785?searchresult=1",
    subject: "Technical",
    authorName: "Jason Loe",
    authorImage:
      "https://images.unsplash.com/photo-1606002830191-c1b4f20e6cda?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=765&q=80",
    date: "24 January 2023",
    category: "Surveillance",
    description: `Reservoir simulation is a critical tool for predicting oil and gas production from subsurface reservoirs. However, traditional simulation methods can be time-consuming and computationally intensive, requiring significant resources and expertise.
    The "Application of Machine Learning in Reservoir Simulation: A Case Study" paper explores the potential of machine learning techniques to improve the accuracy and efficiency of reservoir simulation. The paper presents a case study in which a machine learning algorithm was trained to predict reservoir behavior based on a set of input parameters, such as reservoir pressure, temperature, and fluid properties.
    The results of the study demonstrate that machine learning can significantly reduce the computational time required for reservoir simulation, while also improving the accuracy of predictions. The paper also highlights the potential for machine learning to enable more detailed and comprehensive analysis of reservoir behavior, leading to improved decision-making and reduced risk.
    The paper concludes by discussing the implications of these findings for the oil and gas industry, highlighting the potential for machine learning to revolutionize reservoir simulation and improve the efficiency and profitability of oil and gas operations.`,
  },
  {
    id: 2,
    image:
      "https://images.unsplash.com/photo-1675611559364-4b3e04347077?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=715&q=80",
    title:
      "A New Data Analytics Based Method to Characterize Waterflood Strategy in Geologically Challenging Mature Oil Field",
    heading: "Maximizing Recovery and Reducing Risk in Oil and Gas Operations",
    subject: "Technical",
    link: "https://onepetro.org/SPERPTC/proceedings/20RPTC/2-20RPTC/D023S007R003/450198?searchresult=1",
    authorName: "John Doe",
    authorImage:
      "https://images.unsplash.com/photo-1552374196-c4e7ffc6e126?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    date: "26 October 2020",
    category: "Surveillance",
    description: `Well placement is a critical factor in the success of oil and gas operations, with the location and orientation of wells having a significant impact on production rates and recovery efficiency. Traditional well placement approaches can be time-consuming and subjective, relying on expert knowledge and experience.
    The "Optimizing Well Placement with Geostatistical Analysis: A Case Study" paper explores the potential of geostatistical analysis to improve the accuracy and efficiency of well placement. The paper presents a case study in which geostatistical techniques were used to analyze subsurface data and optimize the placement of new wells.
    The results of the study demonstrate that geostatistical analysis can enable more accurate and data-driven well placement decisions, leading to improved recovery rates and reduced risk. The paper also highlights the potential for geostatistical analysis to enable more efficient exploration and development of new oil and gas reserves.
    The paper concludes by discussing the implications of these findings for the oil and gas industry, highlighting the potential for geostatistical analysis to revolutionize well placement and improve the efficiency and profitability of oil and gas operations.`,
  },
  {
    id: 3,
    image:
      "https://images.unsplash.com/photo-1676024953894-b87031aff7f9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=735&q=80",
    title:
      "Integration of Capacitance Resistance Model with Reservoir Simulation",
    heading:
      "Integration of Capacitance Resistance Model with Reservoir Simulation",
    link: "https://onepetro.org/SPEIOR/proceedings/20IOR/2-20IOR/D021S026R004/448510?searchresult=1",
    subject: "Technical",
    authorName: "Karla Moe",
    authorImage:
      "https://images.unsplash.com/photo-1531123897727-8f129e1688ce?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    date: "30 August 2020",
    category: "Surveillance",
    description: `The oil and gas industry has historically faced criticism for its negative impact on the environment, particularly with respect to greenhouse gas emissions and water usage. As a result, there is increasing pressure on oil and gas companies to reduce their environmental footprint and adopt more sustainable practices.
    The "Reducing Environmental Impact with Integrated Reservoir Management" paper explores the potential for integrated reservoir management (IRM) to help oil and gas companies reduce their environmental impact while maintaining profitability. The paper presents a case study in which IRM was used to optimize the production of an oil reservoir while minimizing water usage and greenhouse gas emissions.
    The results of the study demonstrate that IRM can enable more sustainable and environmentally friendly oil and gas production practices, leading to reduced environmental impact and improved public perception of the industry. The paper also highlights the potential for IRM to enable more efficient and profitable oil and gas operations, through the optimization of production and the reduction of waste and inefficiencies.
    The paper concludes by discussing the implications of these findings for the oil and gas industry, highlighting the potential for IRM to play a key role in the transition to a more sustainable and environmentally friendly energy sector.`,
  },
  {
    id: 4,
    image:
      "https://images.unsplash.com/photo-1661956602868-6ae368943878?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    title:
      "A New Continuous Waterflood Operations Optimization for a Mature Oil Field by using Analytical Workflows that Improve Reservoir Characterization",
    heading: "A Comparative Analysis of Models and Algorithms",
    link: "https://onepetro.org/SPEGOTS/proceedings/19GOTS/2-19GOTS/D021S007R004/218244?searchresult=1",
    subject: "Technical",
    authorName: "Maureen Boe",
    authorImage:
      "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=761&q=80",
    date: "21 October 2019",
    category: "Surveillance",
    description: `Accurately predicting reservoir performance is critical for the success of oil and gas operations, with the ability to predict production rates and recovery efficiency enabling more efficient and profitable operations. Traditional reservoir modeling approaches can be time-consuming and subjective, relying on expert knowledge and experience.
    The "Predicting Reservoir Performance with Machine Learning: A Comparative Analysis of Models and Algorithms" paper explores the potential of machine learning algorithms to improve the accuracy and efficiency of reservoir performance prediction. The paper presents a comparative analysis of different machine learning models and algorithms, evaluating their performance on a real-world dataset.
    The results of the analysis demonstrate the potential for machine learning to enable more accurate and data-driven reservoir performance prediction, leading to improved recovery rates and reduced risk. The paper also highlights the potential for machine learning to enable more efficient exploration and development of new oil and gas reserves.
    The paper concludes by discussing the implications of these findings for the oil and gas industry, highlighting the potential for machine learning to revolutionize reservoir modeling and improve the efficiency and profitability of oil and gas operations.`,
  },
  {
    id: 5,
    image:
      "https://images.unsplash.com/photo-1675872217301-ecaf886173d2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=736&q=80",
    title:
      "A Comprehensive Analysis of Data Driven Techniques to Quantify Injector Producer Relationships",
    heading: "Potential of Digital Oilfield Technology to Optimize Production",
    subject: "Technical",
    link: "https://onepetro.org/SPEGOTS/proceedings/23GOTS/3-23GOTS/D031S046R001/517909?searchresult=1",
    authorName: "Kayla Koe",
    authorImage:
      "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    date: "13 March 2023",
    category: "Surveillance",
    description: `Mature oil and gas fields present a unique challenge for operators, as declining production rates and increasing costs can make it difficult to maintain profitability. However, by leveraging digital oilfield technology, operators can optimize production and extend the life of mature fields.
    The "Maximizing Production from Mature Fields with Digital Oilfield Technology" paper explores the potential of digital oilfield technology to improve the efficiency and productivity of mature oil and gas fields. The paper presents a case study in which digital oilfield technology was used to optimize the production of a mature oil field, resulting in a significant increase in production rates and a reduction in costs.
    The results of the study demonstrate the potential for digital oilfield technology to enable more efficient and productive operations in mature oil and gas fields, leading to improved profitability and sustainability. The paper also highlights the potential for digital oilfield technology to enable more effective collaboration between different teams and stakeholders, improving communication and decision-making.
    The paper concludes by discussing the implications of these findings for the oil and gas industry, highlighting the potential for digital oilfield technology to play a key role in the optimization of mature oil and gas fields and the transition to a more sustainable energy sector.`,
  },
  {
    id: 6,
    image:
      "https://images.unsplash.com/photo-1675935123206-dacc60f43d59?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    title: "Machine Learning Assisted Well Placement Optimization",
    heading:
      "Case Study Shows Best Practices and Innovative Solutions to Mitigate Environmental Risks",
    subject: "Technical",
    link: "https://onepetro.org/SPEWRM/proceedings/23WRM/4-23WRM/D041S015R003/519652?searchresult=1",
    authorName: "George Monroe",
    authorImage:
      "https://images.unsplash.com/photo-1499996860823-5214fcc65f8f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=766&q=80",
    date: "15 May 2023",
    category: "Surveillance",
    description: `Unconventional oil and gas operations, such as hydraulic fracturing and horizontal drilling, have revolutionized the oil and gas industry, unlocking vast new reserves of hydrocarbons. However, these operations also pose unique environmental risks, including air and water pollution, land use impacts, and greenhouse gas emissions.
    The "Mitigating Environmental Risk in Unconventional Oil and Gas Operations: A Case Study of Best Practices and Innovative Solutions" paper explores the best practices and innovative solutions that can be used to mitigate these risks and ensure the responsible development of unconventional oil and gas resources. The paper presents a case study of an unconventional oil and gas operation that implemented a range of environmental risk mitigation strategies, including water management, air emissions control, and community engagement.
    The results of the case study demonstrate the potential for effective environmental risk mitigation in unconventional oil and gas operations, highlighting the importance of proactive measures and innovative solutions. The paper also discusses the potential for new technologies and approaches, such as carbon capture and storage and renewable energy integration, to further reduce the environmental impact of oil and gas operations.
    The paper concludes by discussing the implications of these findings for the oil and gas industry and the broader energy transition, highlighting the importance of balancing economic development with environmental responsibility.`,
  },
  {
    id: 7,
    image:
      "https://images.unsplash.com/photo-1675919038472-6ae71f6c56c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80",
    title:
      "Combining Capacitance Resistance Model with Geological Data for Large Reservoirs",
    heading: "Unlocking the Potential of Offshore Oil and Gas Reservoirs",
    subject: "Technical",
    link: "https://onepetro.org/SPEATCE/proceedings/21ATCE/3-21ATCE/D031S050R003/469558?searchresult=1",
    authorName: "Kathy Lory",
    authorImage:
      "https://images.unsplash.com/photo-1614204424926-196a80bf0be8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    date: "15 September 2021",
    category: "Surveillance",
    description: `Offshore oil and gas reservoirs are an important source of energy for the world, but they are also some of the most challenging to develop and manage. Effective reservoir management is critical to maximizing production and minimizing costs, but there are a variety of different strategies that can be used.
    The "Unlocking the Potential of Offshore Oil and Gas Reservoirs: A Comparison of Reservoir Management Strategies" paper compares and contrasts three different reservoir management strategies: traditional waterflooding, gas injection, and chemical enhanced oil recovery (CEOR). The paper presents a case study of an offshore oil and gas reservoir that has implemented each of these strategies at different times during its production history.
    The results of the case study demonstrate the strengths and weaknesses of each reservoir management strategy, highlighting the importance of understanding the specific characteristics of the reservoir and tailoring the management approach accordingly. The paper also discusses the potential for new technologies and approaches, such as artificial intelligence and machine learning, to further optimize reservoir management and unlock the full potential of offshore oil and gas reservoirs.
    The paper concludes by discussing the implications of these findings for the oil and gas industry and the broader energy transition, highlighting the importance of ongoing innovation and collaboration to meet the world's energy needs sustainably.`,
  },
  {
    id: 8,
    image:
      "https://plus.unsplash.com/premium_photo-1661678226027-5f9ce93804f4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    title:
      "Integration of Capacitance Resistance Model with Reservoir Simulation",
    heading: "Using Advanced Reservoir Simulation and Machine Learning",
    subject: "Technical",
    link: "https://onepetro.org/SPEIOR/proceedings/20IOR/2-20IOR/D021S026R004/448510?searchresult=1",
    authorName: "Chris Doe",
    authorImage:
      "https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80",
    date: "30 August 2020",
    category: "Surveillance",
    description: ` Hydraulic fracturing is a critical technology for the development of unconventional oil and gas reservoirs, but the design of optimal fracturing treatments is a complex and challenging problem. In this paper, we present a novel approach for optimizing hydraulic fracturing design using advanced reservoir simulation and machine learning techniques.
    The "Optimizing Hydraulic Fracturing Design Using Advanced Reservoir Simulation and Machine Learning" paper begins by discussing the limitations of traditional fracture design methods and the potential benefits of using simulation and machine learning to optimize the process. We then present a case study of a hydraulic fracturing treatment in an unconventional oil reservoir, in which we used reservoir simulation and machine learning algorithms to identify the optimal design parameters.
    The results of the case study demonstrate the potential for significant improvements in production and cost efficiency using this approach. The paper also discusses the broader implications of these findings for the oil and gas industry, highlighting the importance of ongoing innovation and collaboration to meet the world's energy needs sustainably.
    Overall, the "Optimizing Hydraulic Fracturing Design Using Advanced Reservoir Simulation and Machine Learning" paper presents a promising new approach to hydraulic fracturing design that could help to unlock the full potential of unconventional oil and gas reservoirs while minimizing environmental impacts and costs.`,
  },
  {
    id: 9,
    image:
      "https://images.unsplash.com/photo-1519865241348-e0d7cd33a287?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=5070&q=80",
    title:
      "A Novel Approach to Combine Models to Evaluate Interwell Connectivity in a Waterflooded Reservoir with Limited Injection History",
    heading: "Using Advanced Reservoir Simulation and Machine Learning",
    subject: "Technical",
    link: "https://onepetro.org/SPEGOTS/proceedings/23GOTS/3-23GOTS/D031S046R004/517949?searchresult=1",
    authorName: "Chris Doe",
    authorImage:
      "https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80",
    date: "13 March 2023",
    category: "Surveillance",
    description: ` Hydraulic fracturing is a critical technology for the development of unconventional oil and gas reservoirs, but the design of optimal fracturing treatments is a complex and challenging problem. In this paper, we present a novel approach for optimizing hydraulic fracturing design using advanced reservoir simulation and machine learning techniques.
    The "Optimizing Hydraulic Fracturing Design Using Advanced Reservoir Simulation and Machine Learning" paper begins by discussing the limitations of traditional fracture design methods and the potential benefits of using simulation and machine learning to optimize the process. We then present a case study of a hydraulic fracturing treatment in an unconventional oil reservoir, in which we used reservoir simulation and machine learning algorithms to identify the optimal design parameters.
    The results of the case study demonstrate the potential for significant improvements in production and cost efficiency using this approach. The paper also discusses the broader implications of these findings for the oil and gas industry, highlighting the importance of ongoing innovation and collaboration to meet the world's energy needs sustainably.
    Overall, the "Optimizing Hydraulic Fracturing Design Using Advanced Reservoir Simulation and Machine Learning" paper presents a promising new approach to hydraulic fracturing design that could help to unlock the full potential of unconventional oil and gas reservoirs while minimizing environmental impacts and costs.`,
  },
  {
    id: 10,
    image:
      "https://plus.unsplash.com/premium_photo-1661901232823-fd68a93377c9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=5071&q=80",
    title:
      "A Hybrid Neural Workflow for Optimal Water-Alternating-Gas Flooding",
    heading: "Using Advanced Reservoir Simulation and Machine Learning",
    subject: "Technical",
    link: "https://onepetro.org/SPERCSC/proceedings/22RCSC/2-22RCSC/D021S013R002/515785?searchresult=1",
    authorName: "Chris Doe",
    authorImage:
      "https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80",
    date: "24 January 2023",
    category: "Surveillance",
    description: ` Hydraulic fracturing is a critical technology for the development of unconventional oil and gas reservoirs, but the design of optimal fracturing treatments is a complex and challenging problem. In this paper, we present a novel approach for optimizing hydraulic fracturing design using advanced reservoir simulation and machine learning techniques.
    The "Optimizing Hydraulic Fracturing Design Using Advanced Reservoir Simulation and Machine Learning" paper begins by discussing the limitations of traditional fracture design methods and the potential benefits of using simulation and machine learning to optimize the process. We then present a case study of a hydraulic fracturing treatment in an unconventional oil reservoir, in which we used reservoir simulation and machine learning algorithms to identify the optimal design parameters.
    The results of the case study demonstrate the potential for significant improvements in production and cost efficiency using this approach. The paper also discusses the broader implications of these findings for the oil and gas industry, highlighting the importance of ongoing innovation and collaboration to meet the world's energy needs sustainably.
    Overall, the "Optimizing Hydraulic Fracturing Design Using Advanced Reservoir Simulation and Machine Learning" paper presents a promising new approach to hydraulic fracturing design that could help to unlock the full potential of unconventional oil and gas reservoirs while minimizing environmental impacts and costs.`,
  },
];
